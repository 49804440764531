import { EmojiTransportation, ShoppingBasket } from "@mui/icons-material/";
import { Box } from "@mui/material";
import { CarListBlock } from "components/CarListBlock";
import { HeaderRow } from "components/HeaderRow";
import { MiniDocumentSlider } from "components/sliders/MiniDocumentSlider";
import { ServiceSlider } from "components/sliders/ServiceSlider";
import { BoxFlexRow } from "data/styleGlobals";
import { ColoredButton } from "pages/main/mobile/ColoredButton";
import { MobileTitle } from "pages/main/mobile/MobileTitle";
import React from "react";
import { MainFooter } from "../MainFooter";

export function MobileMainPage() {
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <MobileTitle />
        <Box sx={{ overflow: "auto" }}>
          {/* <BoxFlexRow
            sx={{
              alignItems: "center !important",
              justifyContent: "space-evenly !important",
              flexWrap: "nowrap !important",
            }}
          >
            <ColoredButton
              icon={
                <EmojiTransportation
                  sx={{ height: "2.0rem", width: "2.0rem" }}
                />
              }
              navigateOnClick="/request"
              text="Запись на сервис"
              variant="standart"
            />
            <ColoredButton
              icon={
                <ShoppingBasket sx={{ height: "1.5rem", width: "1.5rem" }} />
              }
              navigateOnClick="/services"
              text="Услуги и цены"
              variant="standart"
            />
          </BoxFlexRow> */}
          <HeaderRow
            boxShadow="none"
            navigateOnClick="/services"
            text="Услуги и цены"
          />
          <ServiceSlider />
          <MiniDocumentSlider />
          <CarListBlock limit={3} />
          <HeaderRow
            boxShadow="none"
            navigateOnClick="/departments"
            text="Контакты"
          />
          <MainFooter />
        </Box>
      </Box>
    </React.Fragment>
  );
}
