import { HelpOutline, ShoppingBasket } from "@mui/icons-material";
import {
  Badge,
  Button,
  ButtonGroup,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { GetPrices } from "components/gql/queris/GetPrices";
import { SelectedBlock } from "components/registration/SelectedBlock";
import { GetBasket } from "contexts/BasketContext";
import { GetData } from "contexts/DataContext";
import { isObjectEmpty } from "data/functions";
import { ServicesDialog } from "pages/basket/ServicesDialog";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export function RegistrationBlock(props) {
  const { dataObj } = GetData();
  const { getPrice, prices, registration, sections } = GetBasket();
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalType, setTotalType] = useState("");
  const [sale, setSale] = useState(0);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [pricesQuery] = GetPrices();
  const navigate = useNavigate();

  React.useEffect(() => {
    let carID = dataObj?.usedCar?.car?.id || "all";
    let fSalePrice = 0;
    let fPrice = 0;
    let type = "";
    let typeEmpty = "";
    if (!isObjectEmpty(registration?.services)) {
      registration?.services.forEach((el) => {
        let priceObj = getPrice(el?.id, carID, pricesQuery);
        if (priceObj) {
          fSalePrice = fSalePrice + priceObj?.salePrice;
          fPrice = fPrice + priceObj?.price;
          if (priceObj?.priceType === "min_price") type = priceObj?.priceType;
          if (priceObj?.priceType === "empty_price")
            typeEmpty = priceObj?.priceType;
        }
      });
    }
    if (!type && typeEmpty) type = typeEmpty;
    setTotalType(type);
    setTotalPrice(fSalePrice);
    setSale(fPrice - fSalePrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registration, dataObj?.usedCar, prices]);

  return (
    <React.Fragment>
      <Table
        size="small"
        sx={{
          width: "100%",
          "& .MuiTableCell-root": {
            padding: props?.size === "small" ? "0!important" : undefined,
          },

          // props?.size === "small"
        }}
      >
        <TableBody>
          {/* {!isObjectEmpty(registration?.services) && ( */}
          <SelectedBlock
            array={registration?.services || []}
            label="Услуги"
            section={sections?.services}
            size={props?.size}
          />
          {!isObjectEmpty(
            (dataObj?.recsArray ? dataObj?.recsArray : {})[
              dataObj?.usedCar?.car?.id
            ],
          ) &&
            !isObjectEmpty(registration?.recommendations) && (
              <SelectedBlock
                array={registration?.recommendations || []}
                label="Рекомендации"
                section={sections?.recommendations}
                size={props?.size}
              />
            )}
          {/* )} */}
          {dataObj?.presentAccrue && !isObjectEmpty(registration?.gifts) && (
            <SelectedBlock
              array={registration?.gifts || []}
              label="Бонусные услуги"
              section={sections?.gifts}
              size={props?.size}
            />
          )}
          {(totalPrice > 0 || totalType === "empty_price") && (
            <TableRow>
              <TableCell align="right" colSpan={1}>
                Итого:
              </TableCell>

              {totalPrice > 0 ? (
                <TableCell align="right" sx={{ padding: 0, minWidth: "80px" }}>
                  {totalType === "min_price" || totalType === "empty_price"
                    ? "от "
                    : ""}
                  {totalPrice} руб.
                </TableCell>
              ) : (
                totalType === "empty_price" && (
                  <TableCell
                    align="right"
                    sx={{ padding: 0, minWidth: "80px" }}
                  >
                    Цена по запросу
                  </TableCell>
                )
              )}
              <TableCell
                align="center"
                sx={{ padding: "2px !important" }}
                width={20}
              >
                <IconButton
                  edge="end"
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  size="small"
                  sx={{ margin: 0, padding: 2 }}
                >
                  <HelpOutline sx={{ width: "0.875em", height: "0.875em" }} />
                </IconButton>
                <Popover
                  PaperProps={{
                    style: {
                      padding: "0.4rem",
                    },
                  }}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  onClose={() => setAnchorEl(null)}
                  open={Boolean(anchorEl)}
                >
                  Указана примерная стоимость. Обратитесь в автосервис для
                  уточнения.
                </Popover>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <ButtonGroup fullWidth>
        {/* <Button
          color="inherit"
          fullWidth
          onClick={() => setOpen(true)}
          sx={{ textTransform: "none" }}
          variant="text"
        >
          Добавить из каталога
        </Button> */}
        <Button
          color="inherit"
          fullWidth
          onClick={() => navigate("/basket")}
          sx={{ textTransform: "none", justifyContent: "flex-end" }}
          variant="text"
        >
          Добавить из корзины
        </Button>
      </ButtonGroup>
      <ServicesDialog setOpen={setOpen} status={open} />
    </React.Fragment>
  );
}
